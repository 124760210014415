import react,{useEffect, useState } from "react";
import { useNavigate,useSearchParams } from 'react-router-dom';
import { Button, Typography, Box, Grid, useMediaQuery, CircularProgress } from "@mui/material";
import { useTheme,styled } from '@mui/material/styles';
import Carousel from './../../Components/ToolKits/carousel/Carousel';
import { Helmet } from 'react-helmet-async';
import SEO from "../../utils/SEO";
import IamSignInScreen from "./slices/IamSignInScreen";
import RootUserSignInScreen from "./slices/RootUserSignInScreen";
import axios from 'axios';
import {validateEmail, accountIdValidate, isValidAlias} from "../../utils/helpers";
import { collection, doc, setDoc, getDoc, getDocs,query, where, } from "firebase/firestore"; 
import { auth,app,fireStore, functions} from "../../firebase/Firebase";
import {
 
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  setPersistence,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  inMemoryPersistence
} from "firebase/auth";
import RootUser from "./slices/RootUser";

const IamSignIn = ()=>{

    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();
    const isXs = useMediaQuery('(max-width:900px)');
  

    const refererRoute = searchParams.get('redirect_uri');
    const email = searchParams.get('email');
    const iamtype = searchParams.get('iamtype'); // iamUser or rootuser
    const alias = searchParams.get('alias'); // value of iamtype
    const iam = searchParams.get('iam');//either alias or accountId

    const [password, setPassword] = useState('');
    const [erorFromFbSearch, setErrorFromFbSearch] = useState(false);
    const [errorFromCloudSearch, setErrorFromCloudSearch] = useState(false);
    const [tenantData, setTenantData] = useState("");
    const [tenantConfig, setTenantConfig] = useState (null);
    const [tenantLoader, setTenantLoader] = useState (true);

    // mfa
    const [recaptcha, setRecaptcha] = useState(null);

    const handleLogin = (response) => {
        console.log(response);
    };

    // Create a custom styled component with your CSS styles
    const Spinner = styled(CircularProgress)(({ theme }) => ({
      height: 60,
      width: 60,
      position: 'absolute',
      top: '50%',
      left: '50%',
      margin: '-30px 0 0 -30px',
      border: '6px solid rgba(0, 0, 0, 0.2)',
      borderRadius: '100%',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: -6,
        top: -6,
        height: '100%',
        width: '100%',
        borderTop: '6px solid rgba(0, 0, 0, 0.8)',
        borderLeft: '6px solid transparent',
        borderBottom: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderRadius: '100%',
      },
    }));

    //console.log({alias,iam});
    const demo = async() => {
      let tenantId = "idtmain-hkygk";
      const tenant = {tenantId};
      axios
        .post('https://us-central1-benhass-65ea4.cloudfunctions.net/idectsaccountserver/tenantproviderconfig', tenant)
          .then((response) => {
             console.log(response);
            
            
          })
          .catch((error) => {
            console.log(error);
          
          });
    
    }
    // Define a function that calls the getTenantConfig function and updates the state
    const fetchTenantConfig = async () => {

      // lets do firestore , search tenant register
      if (iam.length && alias.length) {
        if (iam === "accountId" || iam ==="alias") {
          if ( accountIdValidate(alias) === true || isValidAlias(alias) === true) {

            try {
              
              const queryTenant = query(collection(fireStore, "tenantRegistry"), where(iam, "==", alias));

              const querySnapshot = await getDocs(queryTenant);
              //console.log(querySnapshot);

              if(querySnapshot.empty === false){
                querySnapshot.forEach((doc) => {
                  //var id = doc.id;
                  var tenantId  = doc.data().tenantId;
                  //console.log(doc.id, " => ", doc.data());
                  // now lets do axios
                  setTenantData(doc.data());

                  const tenant = {tenantId};

                  axios
                    .post('https://us-central1-benhass-65ea4.cloudfunctions.net/idectsaccountserver/tenantconfig', tenant)
                      .then((response) => {
                        // console.log(response.data);
                        const tenantInfo = response.data;
                        //them slide the whole current slide and show another slide purrly for login 
                        //setHideTenancySelectorSpinner(true); // not getting the effct i want
                        if(tenantInfo.status === "ok"){
                          setTenantConfig(tenantInfo.tenant);

                          console.log(tenantInfo);
                          setErrorFromCloudSearch(true);
                          setTenantLoader(false);
                        }
                        
                      })
                      .catch((error) => {
                        console.log(error);
                        console.log("error in getting data",error);
                        setErrorFromCloudSearch(true);
                        // setWaitforTenantApproval(false);
                      });

                });
                
              }else{
                setErrorFromFbSearch(true);
              }

            } catch (error) {
              console.log(error);
            }
          }
        }
        
      }
 
      //const config = await getTenantConfig ();
      //setTenantConfig (config);

    };



    useEffect (() => {
    
      fetchTenantConfig();

      const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': () => {}
      }, auth);
  
      setRecaptcha(recaptchaVerifier);
      
      //console.log(fetchTenantConfig());
      // Return a cleanup function that runs before the component unmounts
      return () => {
        recaptchaVerifier.clear();
        setTenantConfig (null);
        setErrorFromFbSearch(false);
      };
    }, []);


    return(
      <>
        <SEO
          title="Sign In | iDects "
          description="sign in with your credentials to access your account"
          name="iDecide Techscape"
          type="sign in" 
        />
        <div id="recaptcha-container"></div>
        <Box
          sx={{
            display: "flex",
            height: "100dvh",
            alignItems: "center",
            justifyContent: "center",
               
          }}
        >
          <Grid container spacing={2} sx={{ maxWidth: 960, boxShadow: isXs === false ? 3:0 , borderRadius:"6px" }}>
            <Grid item xs={12} md={4} sx={{  borderRadius:"6px 0 0 6px", }}>
              {isXs === false ?
              <Box >
                  <Carousel  />
              </Box>
              :""
              }
            </Grid>
            <Grid item xs={12} md={8}>
            { tenantLoader === true ? 
            <Box sx={{ marginLeft:"50%"}}><Spinner /></Box> :
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "left",
                  p: 4,
                  pt: 1
                }}
              >
                <Typography variant="h4" gutterBottom>
                  Sign In To { alias !== "" ? alias: "Access Without Tenant. "}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Choose an option to continue with accout login.
                </Typography>
                  { iamtype === "rootUser" ? < RootUser recaptchaToken ={recaptcha}/> : <IamSignInScreen signInConfig={tenantConfig} tenantData={tenantData} recaptchaToken ={recaptcha}/>}
                <Typography variant="caption" sx={{ mt: 2 }}>
                  By clicking 'Continue' you agree with our Terms of Use
                  & have read about we collect and how we handle it in our Privacy
                  Policy.
                </Typography>
               
              </Box>
              }
            </Grid>
          </Grid>
        </Box>
      </>
    )


}
export default IamSignIn;