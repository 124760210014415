import React , { useState } from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const CustomRadio = styled(Radio, {
    name: 'CustomRadio',
    slot: 'Root',
    overridesResolver: (props, styles) => styles.root,
  })(({ theme }) => ({
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-checked': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  }));

export default function RadioButtonWithDescription(props) {
    const {getIAM} = props;
    const theme = useTheme();
    // Define a state variable to store the selected value
    const [value, setValue] = useState('iamUser');

    // Define a function to handle the change of the selection
    const handleChange = (event) => {
        event.preventDefault();
        setValue(event.target.value);
        getIAM(event.target.value);
        //console.log(event);
    };

  return (
    <RadioGroup  value={value} onChange={handleChange} sx= {{ mb :1 }}>
      <FormControlLabel 
        value="rootUser"
        control={<CustomRadio />}
        label="Root user - Account owner that performs tasks requiring unrestricted access."
        sx={{
            border: "#FFF",
            padding: " 10px 0",
            '&.Mui-checked': {
              borderColor: "#000",
            },
          }}
          
      />
      <FormControlLabel
        value="iamUser"
        control={<CustomRadio />}
        label="IAM user - User within an account that performs daily tasks."
        sx={{
            border: "#FFF",
            padding: " 10px 0",
            '&.Mui-checked': {
              borderColor: "#000",
            },
          }}
      />
    </RadioGroup>
  );
}
