import { createContext, useContext, useEffect, useState } from "react";
import firebase from 'firebase/app';
import { auth,app,fireStore, } from "../firebase/Firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, setDoc , getDoc, getFirestore , collection } from "firebase/firestore";
import {
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    getMultiFactorResolver,
    GoogleAuthProvider,
    ApplicationVerifier,
    multiFactor,
    RecaptchaVerifier,
    MultiFactorError, MultiFactorResolver,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator, sendEmailVerification,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    User
} from "firebase/auth";
import CircularWithValueLabel from "../Components/ToolKits/CircularWithValueLabel";

// Create a new context
const AuthFirebaseContext = createContext();

const AuthFirebaseContextProvider = ({ children }) => {
  // Define the state or data you want to share
  const [user, setUser] = useState(null);
   const [authLoaded, setAuthLoaded] = useState(false);

  const db = getFirestore(app);
  const functions = getFunctions(app);
  // Define any functions or methods you want to share
  async function storeUserDetails(user, address, firstName, lastName) {
    // Initialize Firebase Firestore.

    // Create a new document in the `users` collection.
    //const userDocument = fireStore.collection('users').doc(user.uid);


    /*
    //when add profile picture to the bucket to return the url for firestore user collection 
    // Upload the profile picture to Cloud Storage.
      const storageRef = firebase.storage().ref(`profile_pictures/${user.uid}`);
      await storageRef.put(profilePicture);

      // Get the profile picture URL.
      const profilePictureUrl = await storageRef.getDownloadURL();

    */

    // Set the user's details in the document.

    await setDoc(doc(db, "users", user.uid), {
        address,
        firstName,
        lastName
      });

  }

  async function createUserAndSendVerificationEmail(email, password) {

    // Create a new user account.
    const userCredential = await createUserWithEmailAndPassword(auth,email, password);
    //console.log('user_made');
    // Send the user a verification email.
    auth.useDeviceLanguage();
    await sendEmailVerification(userCredential.user);
    //console.log('email sent');
    // Return the user credential.
    return userCredential;
  }


  async function createUserAndStoreUserDetails(email, password, address, firstName, lastName) {
    
    // Create the user and send the verification email.
    const userCredential = await createUserAndSendVerificationEmail(email, password);

    //console.log(userCredential);
    // Store the user's details in Firebase.
    await storeUserDetails(userCredential.user, address, firstName, lastName);
    

    // Return the user credential.
    return userCredential;
  }


  async function signUp(email, password, address, firstName, lastName) {   
    const userCredential = await createUserAndStoreUserDetails(email, password, address, firstName, lastName);
    return userCredential;
  }

  async function googleSignInAndStoreData() {
    const googleAuthProvider = new GoogleAuthProvider();
    // Sign in with the GoogleAuthProvider object.
    const userCredential = await signInWithPopup(auth,googleAuthProvider);

    const providerData = userCredential.user;

    // Get the user's provider data from Firestore
    const userRef = db.collection('users').doc(providerData.uid);
    userRef.get().then(doc => {
      if (!doc.exists) {
        
          // Save the user's data to Firestore
        userRef.set({
          providerData
        });
      } 
    });

    // Return the user credential.
    return userCredential;
  }

   async function googleSignIn() {
    const userCredential = await googleSignInAndStoreData();
    return userCredential;
  }


  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  async function logInno(auth, email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential;
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        // The user is a multi-factor user. Second factor challenge is required.
        const resolver = await getMultiFactorResolver(auth, error);
        // ...
      } else if (error.code === 'auth/wrong-password') {
        // Handle other errors such as wrong password.
      }
      return error;
    }
  }

  function logOut() {
    return signOut(auth);
  }

   async function verifyUserEmail(user) {
    try {
      // Send an email verification to the user.
      auth.useDeviceLanguage();
      await sendEmailVerification(user);

      // Return true if the email verification was successfully sent.
      return true;
    } catch (e) {
      // Return false if the email verification failed to send.
      return false;
    }
  }

  function verifyIfUserIsEnrolled(user) {
    // Get the enrolled factors for the user.
    const enrolledFactors = multiFactor(user).enrolledFactors;

    // Return true if the user has at least one enrolled factor, false otherwise.
    return enrolledFactors.length > 0;
  }

  useEffect(() => {
    const subscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log("Auth", currentuser);
      setUser(currentuser);

      setAuthLoaded(true);
    });

    return () => {
      subscribe();
    };
  }, []);

  const loadingComponent = <CircularWithValueLabel />;
  
  // Provide the data and functions through the context
  const contextValue = {
    user,
    googleSignIn,
    signUp,
    logIn,
    logOut,
    verifyIfUserIsEnrolled,
    verifyUserEmail,
    functions,
    httpsCallable,
    loadingComponent,
  };

  return <AuthFirebaseContext.Provider value={contextValue}> {authLoaded ? children : loadingComponent}</AuthFirebaseContext.Provider>;
};
export { AuthFirebaseContext, AuthFirebaseContextProvider };