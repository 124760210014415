import React, { useState, useContext } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { AuthFirebaseContext } from "./../../createContext/AuthFirebaseContext";

const AuthAccessRoute = ({ children }) => {

  const { user } = useContext(AuthFirebaseContext);

  //console.log("Check user in PrivateFB: ", user);
  if (user && user.emailVerified) {
    console.log('veriried')
    return children ? children : <Outlet />;
  }else if (user && !user.emailVerified){
    console.log('email not veriried')
    return <Navigate to="/uncomfirmedverfitcationotp" />;
  }else{
    return <Navigate to="/" />;
  }
};

export default AuthAccessRoute;