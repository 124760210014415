import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Card, CardContent, CardMedia, TextField, Button, Typography, useMediaQuery, CssBaseline, Grid, Alert, Stack } from "@mui/material";
import RadioButtonWithDescription from "./slices/RadioButtonWithDescription";
import RememberCheckbox from "./slices/RememberCheckbox";
import { useNavigate } from 'react-router-dom';
import {validateEmail, accountIdValidate, isValidAlias} from "../../utils/helpers";


const SignIn = () => {
  // use media query to detect screen size
  const theme = useTheme();
  const isXs = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  const [iamValue, setIAMValue] = useState('');
  const [error, setError] = useState('');

  const getRadioValuefromChild = (value) =>{
    setIAMValue(value);
    console.log(value);
  } 

  // handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    // get the form data
    const data = new FormData(e.target);
    var email= data.get("email");
    var alias = data.get("alias");

    console.log({email,iamValue});

    if (email !== "" && iamValue === "rootUser") {
      if (validateEmail(email) === true) {
        // proceed to show password for login 
        setError("");
        navigate(`/tenantrootverifysign?redirect_uri=http://idects.com&origin=accounts&iamtype=${iamValue}&email=${email}`);
      }else{
        setError("Wrong email format");
      }

    } else if (alias !== "" && iamValue === "iamUser"){

      let iamType; 

      if (accountIdValidate(alias) === true) {
        iamType= "accountId";

        setError("");
        navigate(`/tenantiamverifysign?redirect_uri=http://idects.com&origin=accounts&iamtype=${iamValue}&iam=${iamType}&alias=${alias}&email=none`);

      } else if(isValidAlias(alias)=== true) {
        iamType= "alias";

        setError("");
        navigate(`/tenantiamverifysign?redirect_uri=http://idects.com&origin=accounts&iamtype=${iamValue}&iam=${iamType}&alias=${alias}&email=none`);

      }else{
        setError("Wrong Alias/Account ID format");
      }

    }else{
      setError("Provide Either A Email/Account ID or an Alias");
    }

  };

  return (
    <Box
      // main container with full viewport height and width
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
       
      <Card
        // child element with box shadow and 70% width and height
        sx={{
          boxShadow: isXs ? "none" : 6,
          width: isXs ? "100%" : "60%",
          height: isXs ? "100%" : "80%",
          display: "flex",
          flexDirection: isXs ? "column" : "row",
        }}
      >
        {/* first half with image */}
        <CssBaseline />
        <Grid container>
        
            <Grid item sm={6} md={6} xl={6}>
              <CardMedia
                // hide the image on xs screen
                sx={{ display: isXs ? "none" : "block", width: "100%", height: "100%" }}
                image="https://source.unsplash.com/random"
                title="Sign in image"
              />
            </Grid>
            <Grid  item xs={12} sm={6} md={6} xl={6}>
              <CardContent
                  // take full space on xs screen
                  sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                > 
                  <Typography variant="h5" component="h1" sx={{ textAlign: "left", mb: 2 }}>
                    Sign in
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <RadioButtonWithDescription getIAM = { getRadioValuefromChild } />
                    {error !== "" && <Alert severity="error" sx={{ mb:2}}>{error}</Alert> }
                    <TextField
                      required
                      fullWidth
                      id={iamValue === "iamUser"?"alias":"email"}
                      label={iamValue === "iamUser"?"Alias/Account ID":"Email Address"}
                      name={iamValue === "iamUser"?"alias":"email"}
                      type={iamValue === "iamUser"?"text":"email"}
                      autoComplete={iamValue === "iamUser"?"Alias/Account ID":"Email Address"}
                      sx={{ mb: 2 }}
                    />
                    
                    <Button type="submit" variant="contained" sx={{ mb: 2 }}>
                      Continue With Sign In
                    </Button>
                  </form>
                  <RememberCheckbox />
                  <Typography variant="body1" sx={{ fontSize:"9px"}}>
                      By continuing, you agree to the AWS Customer Agreement or other agreement for 
                      AWS services, and the Privacy Notice. This site uses essential cookies. 
                      See our Cookie Notice for more information.
                  </Typography>
                </CardContent>
            </Grid>
        </Grid>
        
        {/* second half with inputs and buttons */}
       
      </Card>
    </Box>
  );
};

export default SignIn;
