import React, { useState, useEffect } from "react";
import { Button,Typography } from "@mui/material";
import "./carousel.css";
import Img3 from "./img/3.jpg" 
import Img4 from "./img/4.jpg" 
import Img6 from "./img/6.jpg" 

const Carousel = () => {
  const slides = [
    {
      image: Img3,
      heading: "Passwordless sign-in",
      content: "Move away from risky passwords and experience one-tap access to Zoho. Download and install OneAuth.",
      href: "https://zoho.to/za_signin_oa_rp",
    },
    {
      image: Img4,
      heading: "MFA for all accounts",
      content: "Secure online accounts with OneAuth 2FA. Back up OTP secrets and never lose access to your accounts.",
      href: "https://zurl.to/ZS_oaauthenticator",
    },
    {
      image: Img6,
      heading: "Improved login security",
      content: "Using only a password to sign in? To strengthen the security of your account, we’re introducing additional verification for any unusual sign-in attempt.",
      href: "https://zurl.to/add_verify",
    },
  ];

  const [activeSlide, setActiveSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let interval;

    const startInterval = () => {
      interval = setInterval(() => {
        if (!isPaused) {
          setActiveSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
        }
      }, 10000); // 10000 milliseconds = 10 seconds
    };

    startInterval();

    const handleMouseEnter = () => {
      setIsPaused(true);
      clearInterval(interval);
    };

    const handleMouseLeave = () => {
      setIsPaused(false);
      startInterval();
    };

    return () => {
      clearInterval(interval);
    };
  }, [isPaused, slides.length]);

  const handleIndicatorClick = (index) => {
    setActiveSlide(index);
  };

  const handleIndicatorMouseEnter = () => {
    setIsPaused(true);
  };

  const handleIndicatorMouseLeave = () => {
    setIsPaused(false);
  };

  

  return (
    <div className="carousel-holder" onMouseEnter={() => setIsPaused(true)} onMouseLeave={() => setIsPaused(false)}>
      <div className="carousel-container">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`carousel-slide ${index === activeSlide ? 'active' : index === (activeSlide + 1) % slides.length ? 'next' : index === (activeSlide - 1 + slides.length) % slides.length ? 'prev' : ''}`}
          >
            <div className="carousel-content">
              <div
                className="banner1_img"
                style={{ backgroundImage: `url(${slide.image})`, backgroundRepeat: 'no-repeat' }}
              ></div>
              <Typography variant="h5"  sx={{ mt:3 ,mb: 3, maxHeight: "30px",textOverflow:"ellipsis", whiteSpace:"nowrap", fontSize:"20px", fontWeight:"700"}}>{slide.heading}</Typography>
              
              <Typography variant="body2" component="p">{slide.content}</Typography>
              <Button
                variant='outlined' sx={{ mt: '8%' }} >
                Learn more
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className="indicator-container">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`indicator-button ${index === activeSlide ? 'active' : ''}`}
            onClick={() => handleIndicatorClick(index)}
            onMouseEnter={handleIndicatorMouseEnter}
            onMouseLeave={handleIndicatorMouseLeave}
          >
            <div className={`indicator-bar`}></div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
