export function validateEmail(email) {
    // This regular expression matches the general format of an email address
    // It allows alphanumeric characters, dots, dashes and underscores before the @ symbol
    // It allows alphanumeric characters, dots and dashes after the @ symbol
    // It requires at least one dot after the @ symbol, followed by at least two alphanumeric characters
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    // The test method returns true if the email matches the regex, false otherwise
    return regex.test(String(email).toLowerCase());
  }

  // Define a function that takes a accountId as a parameter
export function accountIdValidate(accountId) {
 // Check if the input is a string and has a length of 12
 if (typeof accountId === "string" && accountId.length === 12) {
  // Use a regular expression to check if the string contains only numbers
  return /^\d+$/.test(accountId);
}

// If the conditions are not met, return false
return false;
}

// Define a function that takes a variable as a parameter
export function isValidAlias(inputString) {
  // Check if the input is a string and has a length between 3 and 50
  if (typeof inputString === "string" && inputString.length >= 3 && inputString.length <= 50) {
    // Use a regular expression to check if the string contains only alphabets and numbers (no symbols)
    if (/^[a-zA-Z0-9]+$/.test(inputString)) {
      // Check if the string is a mix of alphabets and numbers, but not only numbers
      if (/[a-zA-Z]/.test(inputString) && /\d/.test(inputString) && !/^\d+$/.test(inputString)) {
        return true;
      }
    }
  }

  // If the conditions are not met, return false
  return false;
}
