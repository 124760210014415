import { Link, Typography } from '@mui/material';

export const handleFirebaseAuthLoginError = (firebaseAuthError) => {
    let error;
    switch (firebaseAuthError) {
        case "auth/email-already-in-use":
            error = 'account is in use';
          break;
        case "auth/id-token-expired":
            error ='please refresh your browser an try again later';
          break;
        case "auth/id-token-revoked":
            error = 'user account deleted';
          break;
        case "auth/invalid-email":
            error = 'email address is not valid';
          break;
        case "auth/invalid-password":
            error = 'password incorrect';
          break;
        case "auth/user-not-found":
            error = 'user not found';
          break;
        default:
            error ='try again later or contact adminstrator';
          break;
      }
      return error;
} 

export const getErrorRule = (inputString) => {
    // Use a regular expression to match text inside parentheses
    const regex = /\(([^)]+)\)/;
    
    // Use the match() method to find the first match
    const match = inputString.match(regex);
    
    // If a match is found, return the captured group (text inside parentheses)
    if (match && match[1]) {
      return match[1];
    } else {
      // Return null or a default value if no match is found
      return null;
    }
}

export function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://idt-ghana.com/">
          iDecide Techscape
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
  