import React, { useState, useContext } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import { AuthFirebaseContext } from './../../createContext/AuthFirebaseContext';
import Alert from '@mui/material/Alert';
//import { createUserWithEmailAndPassword, GoogleAuthProvider,signInWithPopup,sendEmailVerification } from "firebase/auth";
//import { auth,app,fireStore, } from "../../firebase/Firebase";
//import { doc, setDoc , getFirestore } from "firebase/firestore";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function errorhandler(error){
  
  switch (error) {
    case "auth/email-already-in-use":
      // Display an error message to the user indicating that the email address is already in use.
      break;
    case "auth/invalid-email":
      // Display an error message to the user indicating that the email address is invalid.
      break;
    case "auth/weak-password":
      // Display an error message to the user indicating that the password is too weak.
      break;
    case "auth/operation-not-allowed":
      // Display an error message to the user indicating that the operation is not allowed.
      break;
    case "auth/too-many-requests":
      // Display an error message to the user indicating that they have made too many requests.
      break;
    default:
      // Display an error message to the user indicating that an unknown error occurred.
      break;
  }
}

function getErrorRule(inputString) {
  // Use a regular expression to match text inside parentheses
  const regex = /\(([^)]+)\)/;
  
  // Use the match() method to find the first match
  const match = inputString.match(regex);
  
  // If a match is found, return the captured group (text inside parentheses)
  if (match && match[1]) {
    return match[1];
  } else {
    // Return null or a default value if no match is found
    return null;
  }
}


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {
  //const { signUp } = useUserAuth();
  const [email, setEmail] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [address, setAddress] = useState('');
  const [errors, setErrors] = useState('');
  const [password, setPassword] = useState('');
  const [sigupTune, setSignupTune] = useState(false);
  //const { googleSignIn, signUp  } = useUserAuth();
  const [error, setError] = useState('');

   const { user, googleSignIn, signUp } = useContext(AuthFirebaseContext);

  //const db = getFirestore(app);
//console.log(UseUserAuth());

  const navigate = useNavigate();
   

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/");
    } catch (error) {
      console.log(error.message);
      //console.log('google log in success')
    }
  };


function validateFormData(fname, lname, email, password) {
  const err = [];

  // Validate first name.
  if (fname === "" || !fname.match(/^[a-zA-Z]+$/)) {
    err.push("First name must be non-empty and contain only letters.");
  }

  // Validate last name.
  if (lname === "" || !lname.match(/^[a-zA-Z]+$/)) {
    err.push("Last name must be non-empty and contain only letters.");
  }

  // Validate email address.
  if (email === "" || !email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
    err.push("Invalid email address.");
  }

  // Validate password.
  if (password === "" || password.length < 8 || !password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)) {
    err.push("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one symbol. eg. Password123!");
  }

  setErrors([...errors]);
  return err;
}


  const handleSubmit = (event) => {
    event.preventDefault();
    //setErrors('');
    const validationErrors  = validateFormData(fname, lname, email, password);
    //console.log(validationErrors);
    setErrors(validationErrors);
    setSignupTune(true);
    //console.log(errors);
    if (validationErrors.length) {
      //console.log(errors);
      setSignupTune(false);
      //setErrors([]);
    } else {
      setErrors();
      // Submit the form.
      handleSubmitFb(event);
      console.log('successssss');
    }
  };



  const handleSubmitFb = async () => {
    //e.preventDefault();
    //setError("");
    

    try {
      await signUp(email, password, address, fname, lname);
      //console.log('Success')
      navigate("/uncomfirmedverfitcationotp");
    } catch (err) {
      
      setErrors([...errors, err.message]);
      console.log(err.message);
    }
  }
/*
  
  appwrite use
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors('');

    const fullname = `${fname} ${lname}`;
    const promise = signUp(email, password, fullname);
    promise.then(
      (response) => {
        console.log(response);
        navigate('/');
      },
      (error) => {
        console.log(error);
        setErrors(error);
      }
    );
  }

  */

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={false} md={7}>
          large pic
        </Grid>
        <Grid item xs={12} md={5}>
          <Box >
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign up
                </Typography>
                { errors && 
                <Alert severity="error" icon={false} sx={{width: '100%', marginTop:'10px'}}><ul>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul></Alert>}
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="given-name"
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        autoFocus
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="family-name"
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox value="allowExtraEmails" color="primary" />}
                        label="I want to receive inspiration, marketing promotions and updates via email."
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    disabled= {sigupTune ? true :false }
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {sigupTune ? 'Creating Account..' :' SIGN UP'}
                  </Button>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link to="/" >
                        Already have an account? Sign in
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Copyright sx={{ mt: 5 }} />
            </Container>
          </Box>
        </Grid>
      </Grid>
      
    </React.Fragment>
  );
}

