import react, {useEffect, useState, Fragment } from "react";
import {InputAdornment , 
    FilledInput,
    InputLabel, 
    FormControl, 
    IconButton, 
    Button, 
    Alert, 
    TextField, 
    Box, 
    Divider, 
    Chip, 
    Grid, 
    Stack,
    useMediaQuery,
    Typography
} from '@mui/material';
import {
    getMultiFactorResolver,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    signInWithEmailAndPassword,
  } from "firebase/auth";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useSearchParams, useNavigate } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useTheme } from '@mui/material/styles';
import AppleIcon from '@mui/icons-material/Apple';
import OtpInput from 'react-otp-input';
import {validateEmail,} from "../../../utils/helpers";
import { handleFirebaseAuthLoginError } from "../../../utils/authFunctions";
import { auth } from "../../../firebase/Firebase";
import axios from 'axios';
import "./../auth.css"; 
import GoogleSignIn from "./GoogleSIgnIn";
import PhoneAuth from "./PhoneAuth";



const IamSignInScreen=(props)=>{
    const { signInConfig, tenantData, recaptchaToken }  = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();
    const isRow = useMediaQuery('(max-width:400px)');
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [loginBtnClick, setLoginBtnClick] = useState(false);
    const [error, setError] = useState("");

    const [iam, setIam] = useState("");
    const alias = searchParams.get('alias');
    const iamMode = searchParams.get('iam');
    const email = searchParams.get('email');

    const [phone, setPhone] = useState('(100) 000-0000');
    

    //forms
    const [iamEmail, setIamEmail] = useState("");
    const [passwordBoxShow, setPasswordBoxShow] = useState(false);
    // mfa
    const [resolve, setResolve] = useState(null);
    const [recaptcha, setRecaptcha] = useState(recaptchaToken);
    const [hints, setHints] = useState();
    const [showSignInMfa, setShowSignInMfa] = useState(false);
    const [verificationId, setVerificationId] = useState('');
    const [OTP, setOTP] = useState(null);
    const [validateBtnClick, setValidateBtnClickBtnClick] = useState(false);
    const [validationCodeError, setValidationCodeError] = useState(null);
    

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleIamPasswordForm = () =>{
        if (iamEmail !== "" && validateEmail(iamEmail)) {
            setPasswordBoxShow(true);  
        }else{
            setError("Empty or INvalid Email Address");
        }
          

    }

    const handleFormLoginWithTenant = async () =>{
       
        //auth = ;
        auth.tenantId = signInConfig.tenantId;

        try {
            // Sign in the user with email and password
            const userCredential = await signInWithEmailAndPassword(auth, iamEmail, password);
            // Get the user's ID token as it is needed to exchange for a session cookie
            const idToken = await userCredential.user.getIdToken();
            // Generate a CSRF token and store it in a cookie
            //const csrfToken = btoa(Math.random().toString());
            //Cookies.set('csrfToken', csrfToken, {secure: true});
            // Call the Cloud Function to create the session cookie
            let tenantId  = signInConfig.tenantId;
            const userData = {idToken,tenantId };
            //document.cookie = `tidtokenbfa=idtmain-hkygk; max-age=432000000; domain=.idects.com; path=/; SameSite=None`;
            axios
              .post('https://us-central1-benhass-65ea4.cloudfunctions.net/idectsaccountserver/tenantassigncookie', userData)
                .then((response) => {
                  console.log(response);
                  // Get the JWT from the response
                  // const token = response.data.tId;
                  document.cookie = `_id-tnt_GT4RTY5639UNCGDTSA=${response.data.cookie}; max-age=1209600; domain=.idects.com; path=/; httpOnly, SameSite=None; Secure`;
                  // document.cookie = `_lid_4RTY5639UNCGDTSLH=${response.data.cookie}; max-age=3600;  path=/; Secure`;
                   // Store the JWT in a cookie with httpOnly and secure flags
                  //document.cookie = `tidtoken=${tenantId}; max-age=1209600; domain=.idects.com; path=/; SameSite=None`;
                  document.cookie = `tntStp=true; max-age=1209600; domain=.idects.com; path=/; SameSite=None; Secure`;
                  document.cookie = `_txsuid=${tenantId}; max-age=1209600; domain=.idects.com; path=/; SameSite=None; Secure`;
                  console.log('cookie made for both',{tenantId });
                  
                })
                .catch((error) => {
                  console.log(error);
                  console.log("error in getting data");
                });
      
            //const result = await sessionLogin({idToken});
            // Redirect the user to the home page
            const refererRoute = searchParams.get('redirect');
      
            if (refererRoute !== '') {
              switch (refererRoute) {
                case "idectsindex":
                  //window.location.replace(`http://idects.com?auth=true&referer=accounts&occ=tnt`);
                  console.log("a");
                  break;
              
                default:
                  //window.location.replace(`http://idects.com`);
                  console.log("b");
                  break;
              }
            }else{
              // means the person come to accounts directly
              //navigate(`/accessfeature/${signInConfig.tenantId}`);
             console.log("c");
            }
           
        } catch (error) {
            if (error.code == 'auth/multi-factor-auth-required') {
              // The user is a multi-factor user. Second factor challenge is required.
              const resolver = getMultiFactorResolver(auth, error);
              setResolve(resolver);
              // console.log(resolver);
              setHints(resolver.hints[0]);
            
              setShowSignInMfa(true);
              const phoneInfoOptions = {
                multiFactorHint: resolver.hints[0],
                session: resolver.session
              };
      
              const phoneAuthProvider = new PhoneAuthProvider(auth); 
      
              phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha)
              .then(function (verificationId) {
                  // verificationId will be needed for sign-in completion.
                  setVerificationId(verificationId);
                  setRecaptcha(null);
              });
      
            } else if (error.code !== 'auth/multi-factor-auth-required') {
                setLoginBtnClick(false);
                handleFirebaseAuthLoginError(error.code);
                
            }
        }
    }

    const handleChangecode = (OTP) => {
        //const newCode = event;
        setOTP(OTP);
        console.log(OTP);
      };

    const vadidateMfa = async () =>{

        //console.log(OTP);
        //setVerificationCode(OTP);
        setValidateBtnClickBtnClick(true);
       
        if( /^\d{6}$/.test(OTP) ){
          try{
            // Ask user for the SMS verification code. Then:
            const cred = PhoneAuthProvider.credential(verificationId, OTP);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    
             // Complete sign-in.
             //console.log('allowed');
            return resolve.resolveSignIn(multiFactorAssertion)
          }catch(err){
            console.log(err);
          }
        }else{
          console.log('invalid code format');
          setValidateBtnClickBtnClick(false);
          setValidationCodeError('Invalid code format');
        }
    
      };

    
    return(
        <>  
        {showSignInMfa === true ? 
            <Box sx={{ margin:'5% auto', maxWidth: '60%'}}>
                <Typography variant='h4' component='h4'>Authenticate 2FA </Typography>
                <Typography variant='body1' component='p'>
                    We have sent a code to { hints.phoneNumber }, your {hints.displayName}. Plese enter the code below to continue 
        
                </Typography>
                {validationCodeError && <Alert severity="error">{validationCodeError}</Alert>} 
                <Box sx={{ paddingTop: '50px', paddingBottom: '20px' }}>
                    <OtpInput
                        value={OTP}
                        onChange={handleChangecode}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        containerStyle ={{width: '100%'}}
                        inputStyle={{width: '50px', height: '50px'}}
                    />
                </Box>
        
                <Button variant='contained' onClick={vadidateMfa}  disabled = { validateBtnClick === true ? true :false }> { validateBtnClick === true ? 'Validating ...' :'Authenticate 2FA' }</Button>
                
            </Box>
        
        :<Fragment>
            {error !== "" && <Alert severity="error" sx={{ mb:1}}>{error}</Alert> }
            { signInConfig.emailSignInConfig['enabled'] === true && 
                <div style={{ position:"relative"}}>
                    <Box className={`signinbox ${passwordBoxShow && "slideout"}`}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="iam"
                            label="Email for Sign with Email or Email Link"
                            name="iam"
                            autoComplete="email"
                            variant="outlined"
                            autoFocus
                            value={iamEmail}
                            onChange={(e) => setIamEmail(e.target.value)}
                            sx={{ 
                                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#212121" },
                                borderColor: "#212121",
                                '& .MuiOutlinedInput-input':{
                                    color:"#212121"
                                },
                                '& .MuiFormLabel-root':{
                                    color:"rgb(18 2 2 / 70%)"
                                }
                            }}
                        />

                        { signInConfig.emailSignInConfig['passwordRequired'] === true &&   
                        <Button
                            
                            type="button"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 1 }}
                            onClick={handleIamPasswordForm}
                        >
                            Continue with email
                        </Button>
                        }
                        { signInConfig.emailSignInConfig['passwordRequired'] === false && 
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 1 }}
                        >
                            EMAIL LINK SIGN IN
                        </Button>
                        }
                    
                    </Box>
                    <Box className={`passwordbox ${passwordBoxShow && "slidein"}`}>
                        <FormControl sx={{ mt: 1, width: '100%' }} variant="filled" >
                            <InputLabel htmlFor="filled-adornment-password">Enter Password</InputLabel>
                            <FilledInput style={{ backgroundColor:"#212121"}}
                                id="filled-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        { signInConfig.emailSignInConfig['passwordRequired'] === true &&   
                            <Button
                                disabled = { loginBtnClick === true ? true :false }
                                type="button"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 1, mb: 1 }}
                                onClick={handleFormLoginWithTenant}
                            >
                                { loginBtnClick === true ? 'Verifying, Almost done' :'Continue With Email' }
                            </Button>
                        }
                    </Box>
                </div>
                }
                <PhoneAuth signInConfig={signInConfig} />
                <Divider >
                    <Chip sx={{ bgcolor : "#212121"}} label="OR PROVIDERS" size="small" />
                </Divider>
                { tenantData.allowSocialProviders &&  
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 2 }} sx={{ mt:1}}>
                    {  tenantData.socialProviders[0] === "google.com" && 
                        <GoogleSignIn recaptcha= {recaptchaToken} signInConfig={signInConfig}/>
                    }
                    {  tenantData.socialProviders[0] === "facebook.com" && 
                        <Button variant="outlined" startIcon={<FacebookIcon />}>
                            FACEBOOK
                        </Button>
                    }
                    {  tenantData.socialProviders[0] === "apple.com" && 
                        <Button variant="outlined" startIcon={<AppleIcon />}>
                            APPLE
                        </Button>
                    }
                </Stack>
                }
            </Fragment>}
        </>
    );
}

export default IamSignInScreen;