import React, { useState,  useCallback } from 'react';
import {
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { motion } from 'framer-motion';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import dark from './themes/dark';
import light from './themes/light';
import SignIn from './Pages/Auth/SignIn';
import SignUp from './Pages/Auth/SignUp';
import IamSignIn from "./Pages/Auth/IamSignIn";
import Verfication from './Pages/Auth/Verfication';
import Featurette from './Pages/Landing/Featurette';
import Mfa from './Pages/Landing/Mfa';
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
//import BackToTop from './Pages/Landing/BackToTop';
//import AppSelect from './Pages/Main/AppSelect';
import NoMatch from './Pages/NotFound/NoMatch';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/montserrat';
import "@fontsource/barlow"; // Defaults to weight 400
import "@fontsource/barlow/400.css"; // Specify weight 100-900
import "@fontsource/barlow/500.css"; // Specify weight 100-900
import "@fontsource/barlow/700.css"; // Specify weight 100-900
import "@fontsource/barlow/800.css"; // Specify weight 100-900
import "@fontsource/barlow/900.css"; // Specify weight 100-900
import "@fontsource/barlow/400-italic.css"; // Specify weight and style [italic,normal]
import AuthAccessRoute from './Components/Auth/AuthAccessRoute';
import PrivateRoute from './Components/Auth/PrivateRoute';
//import { UserAuthContextProvider } from './createContext/userAuthContext';
//import { userAuthContextProvider } from './createContext/UserAuthContext';
import { AuthFirebaseContextProvider, AuthFirebaseContext } from './createContext/AuthFirebaseContext';
import './firebase/Firebase';
import { actionCreators, uiActionCreators } from './store/actions';
import { useCurrentPath } from './Components/ToolKits/useCurrentPath';
import { HelmetProvider } from 'react-helmet-async';
import RootUser from './Pages/Auth/slices/RootUser';


window.onerror = function(error, url, lineNumber, columnNumber, errorObj) {
  console.error(error);

  // Log the error to Firebase Crashlytics./or other reporting service like sentry
  //firebase.crashlytics().recordError(error);
  // Display a fallback UI.
  //document.getElementById('fallback-ui').style.display = 'block';
};

const PageLayout = ({ children }) => children;

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.5,
};

const AnimationLayout = () => {
  const { pathname } = useLocation();
  return (
    <PageLayout>
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
    </PageLayout>
  );
};

const themeSetter = (options) => {
  let themeselector;

  if (options === 'light') {
    themeselector = light;
  } else if (options === 'dark') {
    themeselector = dark;
  }
  return themeselector;
};

function fallbackAuthRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong in login activities.. i will style later:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}




export default function App() {
  const [themer, setThemer] = useState(null);
  const [lastVisitedPage, setLastVisitedPage] = useState(null);

  const uiState = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const { addTodo, removeTodo } = bindActionCreators(actionCreators, dispatch);
  const { getTheme, setTheme } = bindActionCreators(uiActionCreators, dispatch);
  const [userDetails, setUserDetails] = React.useState();
  const [error, setError] = React.useState(null);

  const uiThemeMode = themeSetter(uiState.theme);
  const themetest = createTheme(uiThemeMode);
  const navigate = useNavigate();
  const currentPath = useCurrentPath();
   
  //const { loadingComponent } = useContext(AuthFirebaseContext);
  //const { showBoundary, resetBoundary } = useErrorBoundary();


  // Define a fallback UI component.
  function FallbackUI() {
    return <h1>Something went wrong!</h1>;
  }

  const logError = (errorr, info) => {
    // Do something with the error, e.g. log to an external API
    //logging to file or sewnding to sentry or fireanalytics or so
  };

  const handleAuthError = useCallback((error, info) => {
    console.log(error);
    setError(error);
    //later return ui ....
  }, [setError]);

  

  return (
    <HelmetProvider>
      <ThemeProvider theme={themetest}>
        <AuthFirebaseContextProvider>
          <Routes>

            <Route element={<AnimationLayout />}>

              <Route path="/" element={<ErrorBoundary fallback={fallbackAuthRender} onError={logError}><SignIn /></ErrorBoundary>} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/tenantiamverifysign" element={<IamSignIn />} />
                <Route path="/tenantrootverifysign" element={<RootUser />} />

                <Route element = {<PrivateRoute/>} >
                  <Route path="/uncomfirmedverfitcationotp" element={<ErrorBoundary fallback={<div>Something went wrong</div>}><Verfication /></ErrorBoundary>} />
                </Route>

                <Route element={<AuthAccessRoute />} >
                  <Route path="/enrollsecuritywelcome" element={<ErrorBoundary fallback={<div>Something went wrong</div>}><Mfa /></ErrorBoundary>} />
                  <Route path="/accessfeature" element={<ErrorBoundary fallback={<div>Something went wrong</div>}><Featurette /></ErrorBoundary>} />
                </Route>

              <Route path="*" element={<NoMatch />} />

            </Route>

          </Routes>
        </AuthFirebaseContextProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

